import React, { Component } from "react"
import { Form, Col, Button, FormCheck } from "react-bootstrap"
import { Link } from "gatsby"
import * as emailjs from "emailjs-com"
import ReCAPTCHA from "react-google-recaptcha"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../styles/kontakt.css"

const recaptchaRef = React.createRef()
toast.configure()

var contactSubject = ""
var contactLastname = ""
var contactFirstname = ""
var contactCompany = ""
var contactStreet = ""
var contactPLZ = ""
var contactCity = ""
var contactTelephone = ""
var contactEmail = ""
var contactMessage = ""
var contactCallAM = false
var contactCallPM = false

function onChange(value) {
  console.log("Captcha value:", value)
}

function sendContactMail() {
  var message = ""
  if (contactCallAM) {
    message = message + "[x] Rückruf am Vormittag ist erwünscht <br />"
  }
  if (contactCallPM) {
    message = message + "[x] Rückruf am Nachmittag ist erwünscht <br />"
  }

  message = message + contactMessage

  var template_params = {
    subject: contactSubject,
    lastname: contactLastname,
    firstname: contactFirstname,
    company: contactCompany,
    street: contactStreet,
    plz: contactPLZ,
    city: contactCity,
    tel: contactTelephone,
    mail: contactEmail,
    message: message,
  }
  var service_id = "default_service"
  var template_id = "kontakt"
  emailjs
    .send(
      service_id,
      template_id,
      template_params,
      "user_CoeMUuPX6iAuZT8jjjXAO"
    )
    .then(
      response => {
        console.log("SUCCESS!", response.status, response.text)
      },
      err => {
        console.log("FAILED...", err)
        toast.error("Leider ist ein Fehler aufgetreten")
      }
    )
}

export default class contactForm extends Component {
  constructor() {
    super()
    this.state = {
      buttonDisabled: false,
      buttonText: "Nachricht senden",
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event) {
    event.preventDefault()
    const recaptchaValue = recaptchaRef.current.getValue()
    console.log("Captcha value:", recaptchaValue)
    if (recaptchaValue) {
      sendContactMail()
      this.setState({
        buttonDisabled: true,
        buttonText: "Nachricht wurde versendet",
      })
      toast.success("Nachricht wurde erfolgreich versendet.")
    }
  }

  render() {
    const { buttonDisabled, buttonText } = this.state
    return (
      <Form className="contact-form" onSubmit={e => this.handleSubmit(e)}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridFirstName">
            <Form.Label>
              Vorname <span className="red-text">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={e => (contactFirstname = e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridLastName">
            <Form.Label>
              Nachname <span className="red-text">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={e => (contactLastname = e.target.value)}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="formGridCompany">
          <Form.Label>Firma</Form.Label>
          <Form.Control
            type="text"
            onChange={e => (contactCompany = e.target.value)}
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>
              Straße / Hausnummer <span className="red-text">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={e => (contactStreet = e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridSubject">
            <Form.Label>
              PLZ <span className="red-text">*</span>
            </Form.Label>
            <Form.Control
              required
              type="number"
              onChange={e => (contactPLZ = e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridSubject">
            <Form.Label>
              Stadt <span className="red-text">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={e => (contactCity = e.target.value)}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>
              E-Mail <span className="red-text">*</span>
            </Form.Label>
            <Form.Control
              required
              type="email"
              onChange={e => (contactEmail = e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridSubject">
            <Form.Label>
              Betreff <span className="red-text">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={e => (contactSubject = e.target.value)}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="exampleForm.ControlMessage">
          <Form.Label>
            Nachricht <span className="red-text">*</span>
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            rows="10"
            onChange={e => (contactMessage = e.target.value)}
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridPhone">
            <Form.Label>
              Ihre Rufnummer <span className="red-text">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              onChange={e => (contactTelephone = e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} id="formGridCheckboxNoon">
            <FormCheck type="checkbox">
              <FormCheck.Input
                isInvalid
                type={"checkbox"}
                onChange={e => (contactCallAM = e.target.value)}
              />
              <FormCheck.Label>
                <span className="black-text">Rückrufservice Vormittags</span>
              </FormCheck.Label>
            </FormCheck>
          </Form.Group>
          <Form.Group as={Col} id="formGridCheckboxNoon">
            <FormCheck type="checkbox">
              <FormCheck.Input
                isInvalid
                type={"checkbox"}
                onChange={e => (contactCallPM = e.target.value)}
              />
              <FormCheck.Label>
                <span className="black-text">Rückrufservice Nachmittags</span>
              </FormCheck.Label>
            </FormCheck>
          </Form.Group>
        </Form.Row>
        <Form.Group id="formGridCheckboxDSGVO">
          <FormCheck type="checkbox">
            <FormCheck.Input isInvalid type={"checkbox"} required />
            <FormCheck.Label>
              <span className="black-text">
                Ich bestätige, die{" "}
                <Link className="red-link" to="/datenschutz">
                  DSGVO-konforme Datenschutzerklärung
                </Link>{" "}
                gelesen zu haben und ich stimme zu, dass meine Angaben zwecks
                Kontaktaufnahme gespeichert werden. Ich kann meine Einwilligung
                jederzeit für die Zukunft per Mail an{" "}
                <a
                  className="red-link"
                  href="mailto:info@dachdeckerei-buergel.de"
                >
                  info@dachdeckerei-buergel.de
                </a>{" "}
                widerrufen.
              </span>{" "}
              <span className="red-text">*</span>
            </FormCheck.Label>
          </FormCheck>
        </Form.Group>
        <p />
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LeUnpwUAAAAABjWc-KIXVic30LCboxGGzMYkRSm"
          onChange={onChange}
        />
        <p />
        <Button
          variant="danger"
          size="lg"
          type="submit"
          disabled={buttonDisabled}
        >
          {buttonText}
        </Button>
      </Form>
    )
  }
}
