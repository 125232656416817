import { Col, Container, Image, Row } from "react-bootstrap"
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from "react-icons/fa"

import Carousel from "../components/carouselKontakt"
import Footer from "../components/footer"
import FormContact from "../components/formContact"
import Header from "../components/header"
import OpenHoursTable from "../components/tableOpeningHours"
import React from "react"
import imgJasmin_256 from "../assets/about/kontakt_jasmin_256.jpg"
import imgJasmin_512 from "../assets/about/kontakt_jasmin_512.jpg"
import imgMiro_256 from "../assets/about/kontakt_miro_256.jpg"
import imgMiro_512 from "../assets/about/kontakt_miro_512.jpg"

export default () => (
  <div>
    <Header />
    <Carousel />
    <Container fluid id="Kontaktdaten">
      <Row>
        <Col sm={12} md={true} className="contentbox contact white">
          <Row className="text-center">
            <Col>
              <h2> Ihr Weg zu uns </h2> <hr />
            </Col>
          </Row>
          <p />
          <Row>
            <Col className="text-center">
              <table className="contact-table">
                <thead>
                  <tr>
                    <th colSpan="2">
                      <h5>
                        Dachdeckerei Michael Bürgel e.K. <br />
                        Inh. Miro Graf
                      </h5>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <br />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <FaMapMarkerAlt />
                    </td>
                    <td>Kruppstr. 14</td>
                  </tr>
                  <tr>
                    <td />
                    <td>41540 Dormagen</td>
                  </tr>
                  <tr>
                    <td>
                      <FaPhone className="mirror" />
                    </td>
                    <td>
                      <a className="black-link" href="tel:0049213363294">
                        02133 / 63294
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td className="red-link">
                      <FaEnvelope />
                    </td>
                    <td>
                      <a
                        className="red-link"
                        href="mailto:info@dachdeckerei-buergel.de"
                      >
                        info@dachdeckerei-buergel.de
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col className="text-left">
              <OpenHoursTable TableClass="contact-table" />
              <p />
              Termine außerhalb der Öffnungszeiten nach Vereinbarung
            </Col>
          </Row>
          <p />
          <Row className="text-center">
            <Container>
              <Row noGutters>
                <Col>
                  <Image
                    src={imgMiro_512}
                    fluid
                    srcSet={`${imgMiro_256} 512, ${imgMiro_512} 1024w`}
                  />
                </Col>
                <Col>
                  <div>
                    <Image
                      src={imgJasmin_512}
                      fluid
                      srcSet={`${imgJasmin_256} 512, ${imgJasmin_512} 1024w`}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
          <p />
          <Row className="text-center">
            <Col>
              <hr />
              <h3>
                Wir beraten Sie gerne. <br />
                Ihre Dachdeckerei aus Dormagen.
              </h3>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={true} className="contentbox contact gray">
          <Row className="text-center">
            <Col>
              <h2> Schreiben Sie uns </h2> <hr />
            </Col>
          </Row>
          <Row className="text-left">
            <Col>
              <FormContact />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    <Footer />
  </div>
)
